import React from 'react'
import Layout from '../layouts'

const NotFoundPage = () => (
  <Layout>
    <h1>Whoa there... can't find this page :(</h1>
  </Layout>
)

export default NotFoundPage
